<template>
  <router-link
    :to="to"
    class="wb-link"
  >
    <slot />
  </router-link>
</template>

<script>
export default {
  name: 'AppLink',
  props: {
    to: {
      type: [Object, String]
    }
  },
}
</script>

<style lang="sass" scoped>
.wb-link
  @extend %link-text
  color: rgba(0, 0, 0, 0.5)
  text-decoration: none
  &:hover
    color: rgba(0, 0, 0, 0.6)
  &:active
    color: rgba(0, 0, 0, 0.7)
</style>
