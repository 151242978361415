<template>
  <div
    class="window-wrapper"
    :class="`window-wrapper-${type}`"
  >
    <div
      class="window"
      :class="[`window-${type}`, modalClass]"
    >
      <template v-if="!hideLogo">
        <div
          v-if="logoType === 'small'"
          class="logo-middle"
        >
          <router-link
            style="text-decoration: none"
            tag="a"
            :to="{path: isLoggedIn ? '/' : loginPath}"
          >
            <img
              src="../../../assets/img/logo_small.svg"
              alt="logo organization"
            >
          </router-link>
        </div>
        <div
          v-else
          class="logo-small"
        >
          <router-link
            style="text-decoration: none"
            tag="a"
            :to="{path: isLoggedIn ? '/' : loginPath}"
          >
            <img
              src="../../../assets/img/logo_big.svg"
              alt="logo organization"
            >
          </router-link>
        </div>
      </template>
      <div class="main-header">
        <slot name="header" />
      </div>
      <div class="main-center">
        <slot name="center" />
      </div>
      <div class="main-bottom">
        <slot name="bottom" />
      </div>
    </div>
  </div>
</template>

<script>
import { routeList } from '@/router/user'

export default {
  name: 'AppMainWindowContainer',
  props: {
    type: {
      type: String,
      default: 'middle',
      validator: (val) => ['small', 'middle'].includes(val)
    },
    logoType: {
      type: String,
      default: 'small',
      validator: (val) => ['small', 'big'].includes(val)
    },
    modalClass: {
      type: String
    },
    hideLogo: {
      type: Boolean
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.state.users.info && this.$store.state.users.info.id
    },
    loginPath () {
      return routeList.USER_LOGIN_PATH
    },
  }
}
</script>

<style lang="sass" scoped>
  $middlePaddingTop: 50px
  $smallPaddingTop: 40px

  .window
    position: relative
    display: flex
    flex-direction: column
    background-color: $background-color_primary
    align-self: center

    &-wrapper
      display: flex
      flex-direction: column
      flex-grow: 1

      &-middle
        margin-bottom: auto
        margin-top: $middlePaddingTop + 80px
      &-small
        margin-top: $smallPaddingTop + 80px

    &-middle
      width: 940px
      max-height: 800px
      min-height: 350px
      padding: $middlePaddingTop 70px 80px 70px
      margin-bottom: auto

    &-small
      transform: translateY(8%)
      width: 460px
      padding: $smallPaddingTop 50px 50px

  .logo
    &-small
      position: absolute
      top: -$smallPaddingTop - 70px // margin
      left: 50%
      transform: translateX(-50%)
    &-middle
      position: absolute
      top: -$middlePaddingTop - 50px
      left: 50%
      transform: translateX(-50%)
</style>
