<template>
  <div v-if="id">
    <UpdatePasswordForm
      v-if="!isSuccess"
      :id="id"
      @success="onSuccess"
    />

    <AppMainWindowContainer
      v-else
      type="small"
    >
      <template #header>
        <div class="update-password__header">
          <h1 class="header-text">
            {{ $t('change_password.success.change_password.header') }}
          </h1>
        </div>
      </template>

      <template #center>
        <div class="update-password__center">
          <span class="info-text">
            {{ $t('change_password.success.change_password.description') }}
          </span>
        </div>
      </template>

      <template #bottom>
        <div class="update-password__footer">
          <AppButton
            class="update-password__footer__button"
            @click.native="goToLoginPage"
          >
            {{ $t('change_password.success.change_password.link.auth') }}
          </AppButton>
        </div>
      </template>
    </AppMainWindowContainer>
  </div>
  <AppLoader v-else />
</template>

<script>
import UpdatePasswordForm from '@/components/users/UpdatePasswordForm'
import AppMainWindowContainer from '@/components/common/layout/AppMainWindowContainer'
import AppButton from '@/components/common/simple/AppButton'
import { routeList } from '@/router/user'
import VueCookie from 'vue-cookie'
import AppLoader from '@/components/AppLoader'
import AppConfig from '@/utils/settings'

export default {
  name: 'PageUpdatePassword',
  components: { AppButton, AppMainWindowContainer, UpdatePasswordForm, AppLoader },

  data () {
    return {
      email: null,
      isSuccess: false,
      id: ''
    }
  },

  created () {
    this.getResetPasswordDate()
  },

  methods: {
    getResetPasswordDate () {
      this.$store.dispatch('users/fetchGetPasswordIdByToken', { token: this.$route.params.token })
        .then(({ data }) => {
          if (data.done) {
            this.$router.push({ name: routeList.USER_LOGIN_NAME })
          } else {
            this.id = data.id
            VueCookie.delete(AppConfig.getApiToken())
          }
        })
    },
    goToLoginPage () {
      this.$router.push({ name: routeList.USER_LOGIN_NAME, query: { login: this.email } })
    },
    onSuccess (data) {
      this.isSuccess = true
      this.email = data.id
    }
  },

}
</script>
<style lang="sass" scoped>
.update-password
  &__header
    text-align: center
    margin-bottom: 20px
  &__center
    margin-bottom: 30px
  &__footer
    display: flex
    flex-direction: column
    text-align: center
    align-items: center

    &__button
      width: 90%
</style>
