<template>
  <div class="item-group">
    <div class="item-group__left">
      <slot name="left" />
    </div>
    <div class="item-group__right">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppGroupItemLine'
}
</script>

<style lang="sass" scoped>
  .item-group
    width: 100%
    display: flex
    &__left
      margin-right: 20px
      width: 50%
      & label:not(:first-of-type)
        margin-top: 25px
    &__right
      margin-left: 20px
      width: 50%
      & label:not(:first-of-type)
        margin-top: 25px
</style>
