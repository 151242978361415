<template>
  <AppGroupItemLine>
    <template #left>
      <AppInput
        :value="password"
        :placeholder="$t('field_placeholder.new_password')"
        inputType="password"
        :validation-list="validationLogin"
        @update:value="form.password = $event"
      />
      <AppInput
        :value="repeatPassword"
        :placeholder="$t('field_placeholder.repeat_password')"
        inputType="password"
        show-validation-message
        :validation-list="validationRepeatPassword"
        :clearFocus="repeatPassword !== ''"
        @update:value="form.repeatPassword = $event"
      />
    </template>
    <template #right>
      <AppValidationGroupItems :validations-message="validationLogin" />
    </template>
  </AppGroupItemLine>
</template>

<script>
import AppGroupItemLine from '@/components/common/AppGroupItemLine'
import AppInput from '@/components/common/simple/AppInput'
import AppValidationGroupItems from '@/components/common/AppValidationGroupItems'
import { minLength, equalsStr, onlyLetter, containsNumbers } from '@/utils/validation'
export default {
  name: 'UserPasswordGroupInput',
  components: { AppValidationGroupItems, AppInput, AppGroupItemLine },
  props: {
    password: {
      type: String
    },
    repeatPassword: {
      type: String
    },
  },
  data () {
    return {
      form: {
        password: '',
        repeatPassword: ''
      },
      validationRepeatPassword: [
        {
          text: this.$t('field_validation.repeat_password.equals_password'),
          valid: false, validator: (value) => equalsStr(value, this.form.password) && (value || this.form.password)
        },
      ],
      validationLogin: [
        // {
        //   text: this.$t('field_validation.password.have_upper_case'),
        //   valid: false, validator: (value) => containsUppercaseStr(value)
        // },
        // {
        //   text: this.$t('field_validation.password.have_lower_case'),
        //   valid: false, validator: (value) => containsLowercaseStr(value)
        // },
        {
          text: this.$t('field_validation.password.have_number'),
          valid: false, validator: (value) => containsNumbers(value)
        },
        {
          text: this.$t('field_validation.password.letter_only'),
          valid: false, validator: (value) => onlyLetter(value)
        },
        {
          text: this.$tc('field_validation.password.min_length', { length: 6 }),
          valid: false,
          validator: (value) => minLength(value, 6)
        },
      ]
    }
  },
  computed: {
    validPassword () {
      return this.validationLogin.every((el) => el.valid) && this.validationRepeatPassword.every((el) => el.valid)
    },
  },
  watch: {
    validPassword (newValue) {
      this.$emit('changeValidPassword', newValue)
    },
    'form.password' (newValue) {
      this.$emit('update:password', newValue)
      this.validationLogin.forEach((item) => {
        if (item.validator instanceof Function) {
          item.valid = item.validator(newValue)
        }
      })

      this.validationRepeatPassword.forEach((item) => {
        if (item.validator instanceof Function) {
          item.valid = item.validator(this.form.repeatPassword, newValue)
        }
      })
    },
    'form.repeatPassword' (newValue) {
      this.$emit('update:repeatPassword', newValue)
    }
  },
}
</script>

<style scoped>

</style>
