<template>
  <AppMainWindowContainer>
    <template #header>
      <div class="update-password__header">
        <h1 class="header-text">
          {{ $t('change_password.header') }}
        </h1>
      </div>
    </template>

    <template #center>
      <form @submit.prevent="submitForm">
        <UserPasswordGroupInput
          :password.sync="form.password"
          :repeat-password.sync="form.repeatPassword"
          @changeValidPassword="changeValidPassword"
        />
      </form>
    </template>

    <template #bottom>
      <AppGroupItemLine>
        <template #left>
          <div v-if="isLoading">
            <AppLoader style="margin: 25px auto 0 auto" />
          </div>
          <div v-if="!isLoading">
            <AppButton
              :disabled="!passwordValid"
              @click.native.prevent="submitForm"
            >
              {{ $t('change_password.form.button.submit') }}
            </AppButton>
            <AppLink
              style="display: block; margin-top: 10px; margin-bottom: 10px"
              tag="p"
              :to="{name: userRouteList.USER_LOGIN_NAME}"
              class="login-link-footer__link-item"
            >
              {{ $t('Авторизация') }}
            </AppLink>
            <AppLink
              tag="p"
              :to="{name: userRouteList.USER_ORGANIZATION_REGISTRATION_ROUTE_NAME}"
              class="login-link-footer__link-item"
            >
              {{ $t('login.form.link.registration') }}
            </AppLink>
          </div>
        </template>
      </AppGroupItemLine>
    </template>
  </AppMainWindowContainer>
</template>

<script>
import AppMainWindowContainer from '@/components/common/layout/AppMainWindowContainer'
import UserPasswordGroupInput from '@/components/users/UserPasswordGroupInput'
import AppGroupItemLine from '@/components/common/AppGroupItemLine'
import AppButton from '@/components/common/simple/AppButton'
import AppLink from '@/components/common/simple/AppLink'
import { routeList } from '@/router/user'
import AppLoader from '@/components/AppLoader'

export default {
  name: 'UpdatePasswordForm',
  components: { AppLoader, AppButton, AppGroupItemLine, UserPasswordGroupInput, AppMainWindowContainer, AppLink },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      userRouteList: null,
      form: {
        password: '',
        repeatPassword: '',
      },
      passwordValid: false,
    }
  },
  created () {
    this.userRouteList = routeList
  },
  methods: {
    submitForm () {
      try {
        this.isLoading = true
        this.$store.dispatch('users/fetchUpdatePasswordByToken', { ...this.form, id: this.id })
          .then((response) => {
            this.isLoading = false
            this.$emit('success', response.data)
          })
      } catch (e) {
        this.isLoading = false
      }
    },
    changeValidPassword (value) {
      this.passwordValid = value
    }
  }
}
</script>

<style lang="sass" scoped>
  .update-password
    &__header
      margin-bottom: 30px
</style>
